import React from 'react'
import { graphql } from 'gatsby'
import { Layout } from '@components'
import BlockWrapper from '@pageBuilder/wrapper'
import { HelmetDatoCms } from 'gatsby-source-datocms'

const Page = ({ data }) => {
  const { moduleArea, seoMetaTags } = data.page

  return (
    <Layout>
      <HelmetDatoCms defer={false} seo={seoMetaTags} />
      <BlockWrapper blocks={moduleArea} />
    </Layout>
  )
}

export const query = graphql`
  query pageById($id: String!) {
    page: datoCmsPage(id: { eq: $id }) {
      id
      path
      slug
      title
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      moduleArea {
        ... on DatoCmsButton {
          ...Button
        }
        ... on DatoCmsContent {
          ...Content
        }
        ... on DatoCmsImage {
          ...Image
        }
        ... on DatoCmsBadgeGrid {
          ...BadgeGrid
        }
      }
    }
  }
`
export default Page
